<template>
    <div class="container">
        <div>欢迎使用 {{ wxRes.nickname }}</div>
        <div>设备机器码：{{ this.$route.params.did }}</div>

      <div v-if="showUrl">
        通知终端告警推送地址：
        <div>
          https://api.eyegroup.cc/alerter/pushAlarm/{{uuid}}
        </div>
      </div>

      <div>
        设备最近同步时间：{{heartbeat_time}}
      </div>
      <div>
        <el-button @click="genLink" type="danger">生成新推送地址</el-button>
      </div>
    </div>
</template>
<script>
import request from "@/utils/request"
import {Base64} from "js-base64";
export default {
    name: 'Index',
    data() { return {
        wxRes: {},
        state: '',
        uuid: '',
        showUrl: false,
        heartbeat_time: '从未同步过'
    }},
    mounted() {
        document.title = '获取设备推送地址'
        const code = this.$route.query.code
        if (!code) {
            this.$router.replace(`/error?msg=请使用微信扫码访问`)
        }
        request('get', 'wx/codeToAccessToken', {
            code,
            state: this.$route.query.state || ''
        }).then(res => {
            console.log(res)
            this.wxRes = res.data
            this.state = this.$route.query.state.substr(-1, 1) === '#' ? this.$route.query.state.substr(0, this.$route.query.state.length - 1) : this.$route.query.state
        })
      this.getCurrDevInfo()
    },
  methods:{
      genLink(){

        this.$confirm('更新地址后，旧地址自动失效, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          customClass: 'msgbox'
        }).then(()=>{
          let mid = this.$route.params.mid
          mid = Base64.decode(mid)
          mid = encodeURIComponent(mid)
          request('get', 'alerter/generateLink' + '/'
              +this.$route.params.did,
              {openid: this.wxRes.openid, mid: mid})
              .then(res=>{
                this.uuid = res['data']
                this.showUrl = true
              })
        })
      },
      getCurrDevInfo(){
        // 旧版灯，mid为base64编码过
        let mid = this.$route.params.mid
        mid = Base64.decode(mid)
        mid = encodeURIComponent(mid)
        request('get', 'alerter/getAlarmInfo' + '/'
            +this.$route.params.did,
            {openid: this.wxRes.openid, mid: mid})
            .then(res=>{
              if(res['code'] === 200){
                this.uuid = res['data']['uuid']
                this.heartbeat_time = res['data']['heartbeat_time']
                this.showUrl = true
              }
            })
      }
  }
}
</script>
<style>
.container{
  margin: 10px 10px;
  word-wrap:break-word;
}
@media (max-width: 720px) {
  .msgbox{
    width: 320px !important;
  }
}
</style>